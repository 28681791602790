<template>
  <c-step
    stepperGrpCd="SELF_INSPECTION_RESULT"
    :currentStepCd="currentStatusCd"
    :param="param"
    v-model="selfInspectionStatusCd"
    @keySetting="keySetting"
    @currentStep="currentStep"
    @closePopup="closePopup"
    >
  </c-step>
</template>

<script>
export default {
  name: 'compliance-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        selfInspectionResultId: '',
        selfInspectionStatusCd: '',
        sysRevision: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      selfInspectionStatusCd: '',
      currentStatusCd: '',
      apprData: {
        research: '',
      }
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    param() {
      return {
        selfInspectionResultId: this.popupParam.selfInspectionResultId,
        selfInspectionStatusCd: this.popupParam.selfInspectionStatusCd,
        sysRevision: this.popupParam.sysRevision,
        apprData: this.apprData
      }
    }
  },
  methods: {
    init() {
      this.setStep();
    },
    closePopup() {
      this.$emit('closePopup')
    },
    setStep() {
      if (!this.popupParam.selfInspectionStatusCd || this.popupParam.selfInspectionStatusCd === 'SISC000001') { // 등록시 또는 계획중
        this.selfInspectionStatusCd = 'SIR0000001'
        this.currentStatusCd = 'SIR0000001'
      } else if (this.popupParam.selfInspectionStatusCd === 'SISC000002') { // 계획완료
        this.selfInspectionStatusCd = 'SIR0000002'
        this.currentStatusCd = 'SIR0000003'
      } else if (this.popupParam.selfInspectionStatusCd === 'SISC000003') { // 결재중
        this.selfInspectionStatusCd = 'SIR0000002'
        this.currentStatusCd = 'SIR0000003'
      } else if (this.popupParam.selfInspectionStatusCd === 'SISC000004') { // 감사완료
        this.selfInspectionStatusCd = 'SIR0000004'
        this.currentStatusCd = 'SIR0000004'
      }
    },
    currentStep(step) {
      if (!step || step === 'SISC000001') {
        this.selfInspectionStatusCd = 'SIR0000001'
        this.currentStatusCd = 'SIR0000001'
      } else if (step === 'SISC000002') {
        this.selfInspectionStatusCd = 'SIR0000002'
        this.currentStatusCd = 'SIR0000003'
      } else if (step === 'SISC000003') {
        this.selfInspectionStatusCd = 'SIR0000002'
        this.currentStatusCd = 'SIR0000003'
      } else {
        this.selfInspectionStatusCd = 'SIR0000004'
        this.currentStatusCd = 'SIR0000004'
      }
      this.popupParam.selfInspectionStatusCd = step;
    },
    keySetting(data) {
      this.popupParam.selfInspectionResultId = data.selfInspectionResultId
      this.popupParam.selfInspectionStatusCd = data.selfInspectionStatusCd
      this.popupParam.sysRevision = data.sysRevision
    },
  }
};
</script>
