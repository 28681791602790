var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("c-step", {
    attrs: {
      stepperGrpCd: "SELF_INSPECTION_RESULT",
      currentStepCd: _vm.currentStatusCd,
      param: _vm.param,
    },
    on: {
      keySetting: _vm.keySetting,
      currentStep: _vm.currentStep,
      closePopup: _vm.closePopup,
    },
    model: {
      value: _vm.selfInspectionStatusCd,
      callback: function ($$v) {
        _vm.selfInspectionStatusCd = $$v
      },
      expression: "selfInspectionStatusCd",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }